import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import _ from "lodash";
import PageHeader1 from "../../components/common/PageHeader1";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { validateEmail, validatePhone } from "../../components/common/helper";
import st from "./common.module.scss";
import {
  handleKeyDown,
  handleKeyDownWithNumbers,
} from "../../helper/validation";
import { useNavigate } from "react-router";
import { MdOutlineRefresh } from "react-icons/md";
import { IoChatboxSharp } from "react-icons/io5";
import { DataGrid } from "@mui/x-data-grid";
import CustomToolbar from "../../components/Mui/Filteration/CustomToolbar";
import { CustomPagination } from "../../components/Mui/Pagination/Pagination";
import useSubHeaderComponent from "../../components/Mui/Filteration/Filteration";
import useDebounce from "../../hooks/useDebounce"

function VendorList() {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [table_row, setTable_row] = useState([]);
  const [ismodal, setIsmodal] = useState(false);
  const [iseditmodal, setIseditmodal] = useState(false);
  const [isDeletemodal, setIsDeletemodal] = useState(false);
  const [isApprovemodal, setIsApprovemodal] = useState(false);
  const [user, setuser] = useState(null);
  const [changeStatusTo, setChangeStatusTo] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [reason, setReason] = useState("");
  const [isRejectModal, setIsRejectModal] = useState(false);
  const [reasonError, setReasonError] = useState("");
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState(null)

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 10,
    page: 0,
  });

  const handleReasonChange = (e) => {
    const { value } = e.target;
    setReason(value);
    setReasonError("");
  };
  const validateReason = () => {
    if (!reason.trim()) {
      setReasonError("Reason is required");
      return false;
    }
    return true;
  };

  const handleReject = () => {
    if (validateReason()) {
      rejectProfile({
        is_active: false,
        is_staff: true,
        type: "reject",
        reason: reason, // Include reason in the rejection payload
      });

      setIsRejectModal(false); // Close modal after rejection
    }
  };

  const columns = () => {
    return [
      {
        field: "id",
        headerName: "ID",
        sortable: true,
        width: 100,
      },
      {
        field: "full_name",
        headerName: "Full Name",
        sortable: true,
        width: 150,
      },
      {
        field: "phone",
        headerName: "Phone No.",
        sortable: true,
        width: 150,
      },
      {
        field: "email",
        headerName: "Email",
        sortable: true,
        width: 150,
      },
      {
        field: "created_at",
        headerName: "Registration Date",
        sortable: true,
        width: 150,
        valueGetter: (_, row) => row?.created_at?.split("T")[0],
      },
      {
        field: "plan_name",
        headerName: "Plan",
        sortable: true,
        width: 150,
      },

      {
        headerName: "Document Uploaded",
        field: "document_upload",
        sortable: true,
        width: 150,
        renderCell: ({ row }) => (
          <>
            {row?.is_document ? (
              <button type="button" className="btn btn-success text-white">
                Completed
              </button>
            ) : (
              <button type="button" className="btn btn-danger text-white">
                Incomplete
              </button>
            )}
          </>
        ),
      },
      {
        field: "status",
        headerName: "Status",
        sortable: true,
        width: 150,
        renderCell: ({ row }) => {
          return (
            <>
              {row?.status === "reuploaded document" && row?.is_vendor && (
                <button
                  type="button"
                  className="btn btn-warning text-white fdff"
                  onClick={() => {
                    setIsApprovemodal(true);
                    let status = "";
                    if (row?.is_vendor && !row?.is_active && !row.is_staff) {
                      status =
                        "Are you sure you want to approve this vendor to login?";
                    }

                    setChangeStatusTo({
                      id: row?.id,
                      modelHeading: "Vendor Request to Login",
                      status,
                      cancelButtonText: "Reject",
                      buttonText: "Approved",
                      apiParameters: {
                        is_active: true,
                        is_staff: true,
                      },
                    });
                  }}
                >
                  ReUpload Doc
                </button>
              )}

              {row?.is_vendor && !row?.is_active && !row.is_staff && (
                <button
                  type="button"
                  className="btn btn-warning text-white fdff"
                  onClick={() => {
                    setIsApprovemodal(true);
                    let status = "";
                    if (row?.is_vendor && !row?.is_active && !row.is_staff) {
                      status =
                        "Are you sure you want to approve this vendor to login?";
                    }

                    setChangeStatusTo({
                      id: row?.id,
                      modelHeading: "Vendor Request to Login",
                      status,
                      cancelButtonText: "Reject",
                      buttonText: "Approved",
                      apiParameters: {
                        is_active: true,
                        is_staff: true,
                      },
                    });
                  }}
                >
                  Pending
                </button>
              )}

              {row?.is_vendor && row?.is_active && row.is_staff && (
                <button
                  type="button"
                  className="btn btn-success text-white"
                  onClick={() => {
                    setIsApprovemodal(true);
                    let status = "";

                    if (row?.is_vendor && row?.is_active && row.is_staff) {
                      status =
                        "Are you sure you want to deactivate this vendor account?";
                    }

                    setChangeStatusTo({
                      id: row?.id,
                      modelHeading: "Deactivate Vendor Account",
                      status,
                      buttonText: "Deactivate",
                      cancelButtonText: "Cancel",
                      apiParameters: {
                        is_active: false,
                        is_staff: row?.is_staff,
                      },
                    });
                  }}
                >
                  Approved
                </button>
              )}
              {row?.status === "rejected" &&
                row?.is_vendor &&
                !row?.is_active &&
                row.is_staff && (
                  <button
                    type="button"
                    className="btn btn-danger text-white"
                    onClick={() => {
                      setIsApprovemodal(true);
                      let status = "";

                      if (row?.is_vendor && !row?.is_active && row.is_staff) {
                        status =
                          "Are you sure you want to activate this vendor account?";
                      }

                      setChangeStatusTo({
                        id: row?.id,
                        modelHeading: "Activate Vendor Account",
                        status,
                        buttonText: "Activate",
                        cancelButtonText: "Cancel",
                        apiParameters: {
                          is_active: true,
                          is_staff: row?.is_staff,
                        },
                      });
                    }}
                  >
                    Rejected
                  </button>
                )}

              {row?.status === "inactive" &&
                row?.is_vendor &&
                !row?.is_active &&
                row.is_staff && (
                  <button
                    type="button"
                    className="btn btn-danger text-white"
                    onClick={() => {
                      setIsApprovemodal(true);
                      let status = "";

                      if (row?.is_vendor && !row?.is_active && row.is_staff) {
                        status =
                          "Are you sure you want to activate this vendor account?";
                      }

                      setChangeStatusTo({
                        id: row?.id,
                        modelHeading: "Activate Vendor Account",
                        status,
                        buttonText: "Activate",
                        cancelButtonText: "Cancel",
                        apiParameters: {
                          is_active: true,
                          is_staff: row?.is_staff,
                        },
                      });
                    }}
                  >
                    Inactive
                  </button>
                )}
            </>
          );
        },
      },
      {
        field: "action",
        headerName: "ACTION",
        sortable: true,
        width: 210,
        renderCell: ({ row }) => (
          <div
            className="btn-group"
            role="group"
            aria-label="Basic outlined example"
          >
            <button
              type="button"
              onClick={() => {
                navigate(`/chat/${row?.id}`);
              }}
              className="btn btn-outline-secondary deleterow"
            >
              <IoChatboxSharp />
            </button>
            <button
              onClick={() => {
                setIseditmodal(true);
                reset({
                  ...row,
                  firstName: row.full_name.split(" ")[0],
                  lastName: row.full_name.split(" ")[1],
                });
                setValue("type", "edit");
              }}
              type="button"
              className="btn btn-outline-secondary"
            >
              <i className="icofont-edit text-success"></i>
            </button>
            <button
              type="button"
              onClick={() => {
                setIsDeletemodal(true);
                setuser(row);
              }}
              className="btn btn-outline-secondary deleterow"
            >
              <i className="icofont-ui-delete text-danger"></i>
            </button>
            <button
              type="button"
              onClick={() => {
                row["view"] = true;
                navigate("/vendor-details", { state: row });
              }}
              className="btn btn-outline-secondary deleterow"
            >
              <i className="icofont-eye-alt"></i>
            </button>
          </div>
        ),
      },
    ];
  };

  async function getVendorList(search = "") {
    setIsLoading(true);

    try {

      const searchQuery = search ? `search=${search}&` : "search=&";
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL
        }/api/login_signup/vendor-listing/?page=${paginationModel?.page + 1
        }&page_size=${paginationModel?.pageSize}&start_date=${startDate ?? ''}&end_date=${endDate ?? ''}&${searchQuery}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response?.status == 404) {
        setTable_row([])
        setPaginationModel({
          pageSize: 10,
          page: 0,
        })
      }


      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const { data, count } = await response.json();
      setTable_row(data);
      setTotalRowCount(count);
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    } finally {
      setIsLoading(false);
      reset();
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const debouncedGetProductList = useCallback(_.debounce(getVendorList, 300), [startDate, endDate]);

  // useEffect(() => {
  //   debouncedGetProductList();
  // }, [startDate, endDate]);

  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      data?.type === "add" && formData.append("is_active", true);
      data?.type === "add" && formData.append("is_staff", true);
      formData.append("full_name", data?.full_name);
      formData.append("shop_name", data?.shop_name);
      // formData.append("last_name", data?.lastName);
      data?.type === "add" && formData.append("email", data?.email);
      formData.append("phone", data?.phone);
      data?.type === "add" && formData.append("is_vendor", true);

      formData.append("address", `${data?.address}`);

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/login_signup/user-retrieve/${data?.type === "add" ? "" : data.id + "/"
        }`,
        {
          method: data?.type === "add" ? "POST" : "PATCH",
          body: formData,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        // toast.success(responseData.msg);
        if (response.status === 201) {
          setTimeout(() => {
            toast.success("Vendor created successfully");
            selectSubscription(responseData?.id);
            setIsmodal(false);
          }, 500);
        }
        if (response.status === 200) {
          toast.success("Vendor updated successfully");
          setIseditmodal(false);
        }
        getVendorList();
      } else {
        const data = await response.json();
        // toast.error(Object.values(data).join(", ") || "Something went wrong");
        // for (const [key, value] of Object.entries(data)) {
        //   toast.error(`${key}: ${value}`);
        // }
        for (const [key, value] of Object.entries(data)) {
          const truncatedMessage = `${key}: ${value}`.substring(0, 100);
          toast.error(truncatedMessage);
        }
        console.error("Failed to submit form");
      }
    } catch (error) {
      toast.error("Something went wrong");
      console.error(error, "Error submitting form:");
    }
  };

  const onDeleteSubmit = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/login_signup/user-retrieve/${user?.id}/`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      if (response.status === 204) {
        toast.success("Vendor deleted successfully");
      }
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    } finally {
      setIsDeletemodal();
      getVendorList();
    }
  };

  const selectSubscription = async (user_id) => {
    try {
      // const user = JSON.parse(localStorage.getItem("userData"))?.id;
      const formData = new FormData();
      // formData.append("duration", ); // Assuming data.duration corresponds to duration="1"
      formData.append("user", user_id); // Assuming data.user corresponds to user="119"
      formData.append("plan", 1); // Assuming data.plan corresponds to plan="1"

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/subscriptions/`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: formData,
        }
      );

      if (response.ok) {
        const responseData = await response.json();

        if (response.status === 201) {
          toast.success("Vendor created successfully");

          // setTimeout(() => {
          //   navigate("/sign-in/", { replace: true });
          // }, 1000);
        }
        // getHelpSupportList(); // You may replace this with the appropriate function to update your UI
      } else {
        const data = await response.json();
        console.error("Failed to submit form");
      }
    } catch (error) {
      console.error(error, "Error submitting form:");
    } finally {
      getVendorList();
    }
  };
  const onApproveSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("is_active", changeStatusTo?.apiParameters?.is_active);
      formData.append("is_staff", changeStatusTo?.apiParameters?.is_staff);
      if (changeStatusTo?.modelHeading === "Deactivate Vendor Account") {
        formData.append("status", "inactive");
      } else {
        formData.append("status", "approved");
      }

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/login_signup/user-retrieve/${changeStatusTo?.id}/`,
        {
          method: "PATCH",
          body: formData,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      if (response.status === 200) {
        toast.success("Vendor login status updated");
      }
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    } finally {
      getVendorList();

      setIsApprovemodal(false);
    }
  };

  const rejectProfile = async ({ is_active, is_staff, type }) => {
    try {
      let apiId = changeStatusTo?.id;
      const formData = new FormData();
      formData.append("is_active", is_active);
      formData.append("is_staff", is_staff);
      if (type === "reject") {
        formData.append("reason", reason);
        formData.append("status", "rejected");
      }

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/login_signup/user-retrieve/${apiId}/`,
        {
          method: "PATCH",
          body: formData,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      if (response.status === 200) {
        if (type === "reject") {
          toast.success("Vendor profile rejected successfully");
        } else {
          toast.success("Vendor profile approved successfully");
        }
        setTimeout(() => {
          navigate("/vendor-list", { replace: true });
        }, 500);
      }
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    } finally {
      setIsRejectModal(false);
      setReason("");
      setIsApprovemodal(false);
      getVendorList();
    }
  };

  //Filtered Buyer List
  // const [filterText, setFilterText] = React.useState("");
  // const [resetPaginationToggle, setResetPaginationToggle] =
  //   React.useState(false);
  // const filteredItems = table_row.filter((item) => {
  //   return (
  //     item?.full_name &&
  //     item?.full_name.toLowerCase().includes(filterText.toLowerCase())
  //   );
  // });

  // const subHeaderComponentMemo = React.useMemo(() => {
  //   const handleClear = () => {
  //     if (filterText) {
  //       setResetPaginationToggle(!resetPaginationToggle);
  //       setFilterText("");
  //     }
  //   };
  //   return (
  //     <FilterComponent
  //       onFilter={(e) => setFilterText(e.target.value)}
  //       onClear={handleClear}
  //       filterText={filterText}
  //     />
  //   );
  // }, [filterText, resetPaginationToggle]);

  // Filtering Product based on Product Title
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const debouncedSearchTerm = useDebounce(filterText, 500);

  // Debounce the filtering function
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFilter = useCallback(
    _.debounce((input) => {
      getVendorList(input.toLowerCase());
    }, 1000), // Adjust the delay as needed
    [startDate, endDate]
  );

  const handleFilterChange = (e) => {
    const value = e.target.value;
    setFilterText(value.toLowerCase());
    // debouncedFilter(value);
  };

  const filteredItems = useMemo(() => {
    return table_row.filter((item) => {
      return (
        item?.full_name &&
        item?.full_name.toLowerCase().includes(filterText.toLowerCase())
      );
    });
  }, [filterText, table_row]);

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
      // getVendorList("");
    }

    setStartDate(null)
    setEndDate(null)

  };
  const handleDateFilterChange = (startDate, endDate) => {
    console.log(filterText);

    setStartDate(startDate)
    setEndDate(endDate)
    console.log(startDate, endDate, "start date end date")
  }

  const subHeaderComponentMemo = useSubHeaderComponent({
    filterText,
    resetPaginationToggle,
    handleFilterChange,
    handleClear,
    handleDateFilterChange,
    startDate: startDate,
    endDate: endDate

  });

  useEffect(() => {
    getVendorList(debouncedSearchTerm);

  }, [paginationModel, debouncedSearchTerm, startDate, endDate]);

  return (
    <div className="body d-flex">
      <div className="container-xxl">
        <PageHeader1
          pagetitle="Vendor Information"
          modalbutton={() => {
            return (
              <div className="col-auto d-flex w-sm-100">
                <button
                  type="button"
                  onClick={() => {
                    setIsmodal(true);
                    reset({ type: "add" });
                    setValue("type", "add");
                  }}
                  className="btn btn-primary btn-set-task w-sm-100"
                >
                  <i className="icofont-plus-circle me-2 fs-6"></i>Add Vendor
                </button>
              </div>
            );
          }}
        />
        <div
          className={`d-flex align-items-center justify-content-between ${st.tabsTable}`}
        >
          <ul>
            <li onClick={() => {
              getVendorList(debouncedSearchTerm)
            }}>
              <MdOutlineRefresh />
            </li>
          </ul>
        </div>
        <div className="row clearfix g-3">
          <div className="col-sm-12 mt-3">
            <div className="card ">
              <div className="card-body">
                <div
                  id="myProjectTable_wrapper"
                  className="dataTables_wrapper dt-bootstrap5 no-footer"
                >
                  <div className="row">
                    <div className="col-sm-12">
                      {/* {isLoading ? (
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ minHeight: "70vh" }}
                        >
                          <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      ) : ( */}
                      <DataGrid
                        rows={table_row}
                        columns={columns()}
                        autoHeight
                        loading={isLoading}
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        disableToolbarButton={true}
                        paginationMode="server"
                        pageSizeOptions={[10, 20, 50, 100]}
                        paginationModel={paginationModel}
                        rowCount={totalRowCount}
                        onPaginationModelChange={setPaginationModel}
                        slots={{
                          toolbar: CustomToolbar,
                          pagination: CustomPagination,
                        }}
                        slotProps={{
                          toolbar: {
                            printOptions: { disableToolbarButton: true },
                            csvOptions: { disableToolbarButton: true },
                            subHeaderComponentMemo, // Pass the memoized component to the toolbar
                          },
                        }}
                        sx={{
                          border: "none", // Remove the border of the DataGrid
                          "& .MuiDataGrid-root": {
                            border: "none",
                          },
                        }}
                      />
                      {/* )} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={iseditmodal}
        onHide={() => {
          setIseditmodal(false);
          reset({});
        }}
        className=""
        style={{ display: "block" }}
      >
        <Modal.Header className="modal-header" closeButton>
          <h5 className="modal-title  fw-bold" id="expeditLabel">
            {" "}
            Edit Vendor
          </h5>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <div className="deadline-form">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row g-3 mb-3">
                <div className="col-sm-12">
                  <label htmlFor="item" className="form-label">
                    Full name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Full Name"
                    id="item"
                    {...register("full_name", { required: true })}
                    onKeyDown={handleKeyDown} // Add event handler for keydown
                  />
                  {errors.full_name && (
                    <span className="text-danger">Full name is required</span>
                  )}
                </div>
                <div className="col-sm-12">
                  <label htmlFor="item" className="form-label">
                    Shop name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="item"
                    placeholder="Shop Name"
                    {...register("shop_name", { required: true })}
                    onKeyDown={handleKeyDownWithNumbers} // Add event handler for keydown
                  />
                  {errors.shop_name && (
                    <span className="text-danger">Shop name is required</span>
                  )}
                </div>
                <div className="col-sm-12">
                  <label htmlFor="item" className="form-label">
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="item"
                    placeholder="Email"
                    {...register("email", {
                      required: true,
                      validate: validateEmail,
                    })}
                    defaultValue=""
                  />
                  {errors.email && errors.email.type === "required" && (
                    <span className="text-danger">Email is required.</span>
                  )}
                  {errors.email && errors.email.type === "validate" && (
                    <span className="text-danger">
                      Please enter a valid email address.
                    </span>
                  )}
                </div>
                <div className="col-sm-12">
                  <label htmlFor="item" className="form-label">
                    Phone Number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="item"
                    placeholder="Phone Number"
                    {...register("phone", {
                      required: true,
                      validate: validatePhone,
                    })}
                    defaultValue=""
                  />
                  {errors.phone && errors.phone.type === "required" && (
                    <span className="text-danger">
                      Phone number is required.
                    </span>
                  )}
                  {errors.phone && errors.phone.type === "validate" && (
                    <span className="text-danger">
                      Please enter a valid phone number.
                    </span>
                  )}
                </div>
              </div>
              <div className="row g-3 mb-3">
                <div className="col-sm-6">
                  <label htmlFor="depone" className="form-label">
                    Address
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="depone"
                    placeholder="Address"
                    {...register("address", { required: true })}
                  />
                  {errors.address && (
                    <span className="text-danger">Address is required</span>
                  )}
                </div>
              </div>
              <div className={`${st.modalButtonBottom}`}>
                <button
                  onClick={() => {
                    setIseditmodal(false);
                  }}
                  type="button"
                  className="btn btn-secondary"
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={ismodal}
        onHide={() => {
          setIsmodal(false);
          reset({});
        }}
        style={{ display: "block" }}
      >
        <Modal.Header className="modal-header" closeButton>
          <h5 className="modal-title  fw-bold" id="expaddLabel">
            Add Vendor
          </h5>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <div className="deadline-form">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row g-3 mb-3">
                <div className="col-sm-12">
                  <label htmlFor="item" className="form-label">
                    Full name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="item"
                    placeholder="Full Name"
                    {...register("full_name", { required: true })}
                    onKeyDown={handleKeyDown} // Add event handler for keydown
                  />
                  {errors.full_name && (
                    <span className="text-danger">Full name is required</span>
                  )}
                </div>
                <div className="col-sm-12">
                  <label htmlFor="item" className="form-label">
                    Shop name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Shop Name"
                    id="item"
                    {...register("shop_name", { required: true })}
                    onKeyDown={handleKeyDownWithNumbers} // Add event handler for keydown
                  />
                  {errors.shop_name && (
                    <span className="text-danger">Shop name is required</span>
                  )}
                </div>
                <div className="col-sm-12">
                  <label htmlFor="item" className="form-label">
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="item"
                    placeholder="Email"
                    {...register("email", {
                      required: true,
                      validate: validateEmail,
                    })}
                    defaultValue=""
                  />
                  {errors.email && errors.email.type === "required" && (
                    <span className="text-danger">Email is required.</span>
                  )}
                  {errors.email && errors.email.type === "validate" && (
                    <span className="text-danger">
                      Please enter a valid email address.
                    </span>
                  )}
                </div>
                <div className="col-sm-12">
                  <label htmlFor="item" className="form-label">
                    Phone Number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="item"
                    placeholder="Phone Number"
                    {...register("phone", {
                      required: true,
                      validate: validatePhone,
                    })}
                    defaultValue=""
                  />
                  {errors.phone && errors.phone.type === "required" && (
                    <span className="text-danger">
                      Phone number is required.
                    </span>
                  )}
                  {errors.phone && errors.phone.type === "validate" && (
                    <span className="text-danger">
                      Please enter a valid phone number.
                    </span>
                  )}
                </div>
              </div>
              <div className="row g-3 mb-3">
                <div className="col-sm-6">
                  <label htmlFor="depone" className="form-label">
                    Address
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Address"
                    id="depone"
                    {...register("address", { required: true })}
                  />
                  {errors.address && (
                    <span className="text-danger">Address is required</span>
                  )}
                </div>
              </div>
              <div className={`${st.modalButtonBottom}`}>
                <button
                  onClick={() => {
                    setIsmodal(false);
                    reset();
                  }}
                  type="button"
                  className="btn btn-secondary"
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={isDeletemodal}
        onHide={() => {
          setIsDeletemodal(false);
        }}
        style={{ display: "block" }}
      >
        <Modal.Header className="modal-header" closeButton>
          <h5 className="modal-title  fw-bold" id="expaddLabel">
            Delete Vendor
          </h5>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <div className="deadline-form">
            <form>
              <h4
                style={{
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "600",
                  marginBottom: "20px",
                }}
              >
                Are you sure you want to delete?
              </h4>
              <div className={`${st.modalButtonBottom}`}>
                <button
                  onClick={() => {
                    setIsDeletemodal(false);
                  }}
                  type="button"
                  className="btn btn-secondary"
                >
                  Cancel
                </button>
                <button
                  onClick={onDeleteSubmit}
                  type="button"
                  className="btn btn-primary"
                >
                  Delete
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={isApprovemodal}
        onHide={() => {
          setIsApprovemodal(false);
        }}
        style={{ display: "block" }}
      >
        <Modal.Header className="modal-header" closeButton>
          <h5 className="modal-title  fw-bold" id="expaddLabel">
            {changeStatusTo?.modelHeading}
          </h5>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <div className="deadline-form">
            <form>
              <h4
                style={{
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "600",
                  marginBottom: "20px",
                }}
              >
                {" "}
                {changeStatusTo.status}
              </h4>
              <div className={`${st.modalButtonBottom}`}>
                <button
                  onClick={() => {
                    if (changeStatusTo?.cancelButtonText === "Reject") {
                      setIsRejectModal(true);
                    } else {
                      setIsApprovemodal(false);
                    }
                  }}
                  type="button"
                  className={`btn btn-${changeStatusTo?.cancelButtonText === "Reject"
                    ? "danger"
                    : "secondary"
                    }`}
                >
                  {changeStatusTo?.cancelButtonText}
                </button>
                <button
                  onClick={onApproveSubmit}
                  type="button"
                  className="btn btn-primary"
                >
                  {changeStatusTo?.buttonText}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={isRejectModal}
        onHide={() => {
          setIsRejectModal(false);
        }}
        style={{ display: "block" }}
      >
        <Modal.Header className="modal-header" closeButton>
          <h5 className="modal-title  fw-bold" id="expaddLabel">
            Reason to reject vendor profile
          </h5>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <div className="deadline-form">
            <div>
              <label>Reason:</label>
              <textarea
                rows={5}
                value={reason}
                className="w-100 rounded mt-2 mb-3"
                onChange={handleReasonChange}
              ></textarea>
              {reasonError && <div className="text-danger">{reasonError}</div>}
            </div>
            <div className={`d-flex gap-2 ${st.modalButtonBottom}`}>
              <button
                onClick={() => {
                  setIsRejectModal(false);
                  setReason("");
                  setReasonError("");
                }}
                type="button"
                className="btn btn-secondary"
              >
                Cancel
              </button>
              <button
                onClick={handleReject}
                type="button"
                className="btn btn-primary"
              >
                Reject
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default VendorList;
