import React, { useEffect } from "react";
import $ from "jquery";
import "daterangepicker/daterangepicker.css";
import "daterangepicker";
import { getSubdomain } from "../../helper/subdomainRouting";

export const FilterComponent = ({ filterText, onFilter, onClear, handleDateFilterChange, startDate = null, endDate = null }) => {


  const isDisplayFilterPath = ["/buyer-list", "/vendor-list", '/product-list']
  const subdomain = getSubdomain();

  useEffect(() => {
    $("#date-range-picker-filter").daterangepicker({
      autoUpdateInput: false, // Prevent auto-filling the input field
      locale: {
        cancelLabel: "Clear",
        format: "YYYY-MM-DD",
      },
    });

    $("#date-range-picker-filter").on("apply.daterangepicker", function (e, picker) {
      const start = picker.startDate.format("YYYY-MM-DD");
      const end = picker.endDate.format("YYYY-MM-DD");
      $(this).val(`${start} - ${end}`);
      if (typeof handleDateFilterChange == 'function') {
        handleDateFilterChange(start, end)
      }
    });

    $("#date-range-picker-filter").on("cancel.daterangepicker", function () {
      $(this).val(""); // Clear the input field

      const datePicker = $(this).data("daterangepicker"); // Get the date picker instance

      if (datePicker) {
        datePicker.setStartDate(""); // Reset to empty value
        datePicker.setEndDate("");   // Reset to empty value
        datePicker.setStartDate(new Date()); // Temporarily set a valid date
        datePicker.setEndDate(new Date());

      }

      if (typeof handleDateFilterChange === "function") {
        handleDateFilterChange(null, null); // Ensure state is cleared
      }
    });

  }, [])

  const handleClear = () => {
    const datePicker = $("#date-range-picker-filter").data("daterangepicker");
    if (datePicker) {
      datePicker.setStartDate(""); // Reset start date
      datePicker.setEndDate("");   // Reset end date  
      datePicker.setStartDate(new Date()); // Temporarily set a valid date
      datePicker.setEndDate(new Date());
      datePicker.element.val("");

    }

    onClear()
  }


  const isDisplayResetFilter = (startDate || endDate || filterText) ? true : false
  

  return (
    <div className="searchBarDesign d-flex w-100 justify-content-end">

      <div className="filter-date-range-picker d-flex flex-wrap gap-3">
        {
          (isDisplayFilterPath?.includes(window?.location?.pathname) && subdomain == 'admin') ? (
            <input
              id="date-range-picker-filter"
              type="text"
              placeholder="Filter By Date"
              aria-label="Search Input"
            />
          ) : null
        }

        <input
          id="search"
          type="text"
          placeholder="Search..."
          aria-label="Search Input"
          value={filterText}
          onChange={onFilter}
        />
        {
          isDisplayResetFilter && (
            <button type="button" onClick={handleClear}>
              <svg
                height="20px"
                width="20px"
                fill="#fff"
                style={{ marginTop: "-2px" }}
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 492 492"
                xmlSpace="preserve"
              >
                <g>
                  <g>
                    <path
                      d="M300.188,246L484.14,62.04c5.06-5.064,7.852-11.82,7.86-19.024c0-7.208-2.792-13.972-7.86-19.028L468.02,7.872
                  c-5.068-5.076-11.824-7.856-19.036-7.856c-7.2,0-13.956,2.78-19.024,7.856L246.008,191.82L62.048,7.872
                  c-5.06-5.076-11.82-7.856-19.028-7.856c-7.2,0-13.96,2.78-19.02,7.856L7.872,23.988c-10.496,10.496-10.496,27.568,0,38.052
                  L191.828,246L7.872,429.952c-5.064,5.072-7.852,11.828-7.852,19.032c0,7.204,2.788,13.96,7.852,19.028l16.124,16.116
                  c5.06,5.072,11.824,7.856,19.02,7.856c7.208,0,13.968-2.784,19.028-7.856l183.96-183.952l183.952,183.952
                  c5.068,5.072,11.824,7.856,19.024,7.856h0.008c7.204,0,13.96-2.784,19.028-7.856l16.12-16.116
                  c5.06-5.064,7.852-11.824,7.852-19.028c0-7.204-2.792-13.96-7.852-19.028L300.188,246z"
                    />
                  </g>
                </g>
              </svg>
            </button>

          )
        }
      </div>


    </div>
  );
}
