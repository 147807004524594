import { useMemo } from "react";
import { FilterComponent } from "../../common/FilterComponent";

const useSubHeaderComponent = ({
  filterText,
  resetPaginationToggle,
  handleFilterChange,
  handleClear,
  handleDateFilterChange,
  startDate,
  endDate
}) => {
  const subHeaderComponentMemo = useMemo(() => {
    return (
      <FilterComponent
        onFilter={handleFilterChange}
        onClear={handleClear}
        filterText={filterText}
        handleDateFilterChange={handleDateFilterChange}
        startDate={startDate}
        endDate={endDate}
      />
    );
  }, [filterText, resetPaginationToggle, startDate, endDate]);

  return subHeaderComponentMemo;
};

export default useSubHeaderComponent;
