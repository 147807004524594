import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Modal } from "react-bootstrap";
import PageHeader1 from "../../components/common/PageHeader1";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import st from "./DynamicForm.module.scss";
import { useNavigate } from "react-router";
import { MdOutlineRefresh } from "react-icons/md";
import _ from "lodash";
import { DataGrid } from "@mui/x-data-grid";
import { CustomPagination } from "../../components/Mui/Pagination/Pagination";
import useSubHeaderComponent from "../../components/Mui/Filteration/Filteration";
import CustomToolbar from "../../components/Mui/Filteration/CustomToolbar";
import { changeDateFormat } from "../../helper/changeDateFormat";

function VendorProductList() {
  const navigate = useNavigate();
  const { reset, setValue } = useForm();
  const [isDeletemodal, setIsDeletemodal] = useState(false);
  // Default page size
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [nextPageUrl, setNextPageUrl] = useState("");

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 10,
    page: 0,
  });

  const [table_row, setTable_row] = useState([]);
  const [table_row_draft, setTable_row_draft] = useState([]);
  const [active, setActive] = useState(false);
  const [iseditmodal, setIseditmodal] = useState(false);
  const [user, setuser] = useState(null);
  const [ismodel, setIsModel] = useState(false);
  const userData = useMemo(
    () => JSON.parse(localStorage.getItem("userData")) || {},
    []
  );
  const token = useMemo(() => localStorage.getItem("token"), []);
  const [changeStatusTo, setChangeStatusTo] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const columns = () => {
    return [
      {
        field: "id",
        headerName: "ID",
        sortable: true,
        width: 100,
      },
      {
        field: "title",
        headerName: "Title",
        sortable: true,
        width: 200,
      },
      {
        field: "product_images",
        headerName: "Images",
        sortable: false,
        width: 150,
        renderCell: (params) => {
          const { product_images } = params.row;
          return product_images?.length > 0 ? (
            <img
              src={product_images[0]?.image_url}
              alt="productImg"
              style={{ width: "50px", height: "50px", borderRadius: "50%" }}
            />
          ) : (
            <span>No Image</span>
          );
        },
      },
      {
        field: "category_name",
        headerName: "Category",
        sortable: true,
        width: 200,
        valueGetter: (_, row) => row?.category?.category_name,
      },
      {
        field: "subcategory_name",
        headerName: "Sub Category",
        sortable: true,
        width: 200,
        valueGetter: (_, row) => row?.sub_category?.subcategory_name,
      },
      {
        field: "created_at",
        headerName: "Created At",
        sortable: true,
        width: 200,
        valueGetter: (_, row) => changeDateFormat(row?.created_at),
      },
      {
        field: "full_name",
        headerName: "Vendor",
        sortable: true,
        width: 200,
        valueGetter: (_, row) => row?.user?.full_name,
      },
      {
        field: "availability_status",
        headerName: "Availability",
        sortable: true,
        width: 150,
        renderCell: (params) => (
          <button
            type="button"
            className={
              params.row.availability_status === "available"
                ? "btn btn-success text-white"
                : "btn btn-danger text-white"
            }
            onClick={(event) => {
              event.stopPropagation(); // Prevent row click

              setIsModel(true);
              setChangeStatusTo({
                id: params.row.id,
                modelHeading: "Change Availability Status",
                status:
                  params.row.availability_status === "available"
                    ? "out of stock"
                    : "available",
                apiParameters: {
                  availability_status:
                    params.row.availability_status === "available"
                      ? "out of stock"
                      : "available",
                },
              });
            }}
          >
            {params.row.availability_status === "available"
              ? "Available"
              : "Out Of Stock"}
          </button>
        ),
      },
      {
        field: "status",
        headerName: "Status",
        sortable: true,
        width: 150,
        renderCell: (params) => (
          <button
            type="button"
            className={
              params.row.status === "active"
                ? "btn btn-success text-white"
                : "btn btn-danger text-white"
            }
            onClick={(event) => {
              event.stopPropagation(); // Prevent row click

              setIsModel(true);
              setChangeStatusTo({
                id: params.row.id,
                modelHeading: "Change Product Status",
                status: params.row.status === "active" ? "inactive" : "active",
                apiParameters: {
                  status:
                    params.row.status === "active" ? "inactive" : "active",
                },
              });
            }}
          >
            {params.row.status === "active" ? "Active" : "Inactive"}
          </button>
        ),
      },
      {
        field: "action",
        headerName: "ACTION",
        sortable: false,
        width: 200,
        renderCell: (params) => (
          <div className="btn-group">
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={(event) => {
                event.stopPropagation(); // Prevent row click

                setIseditmodal(true);
                setValue("type", "edit");
                navigate(`/product-details/${params.row.id}`, {
                  state: {
                    type: "edit",
                    id: params.row.id,
                    title: params.row.title,
                    original_price: params.row.original_price,
                    discount_price: params.row.discount_price,
                    description: params.row.description,
                    warranty: params.row.warranty,
                    category: {
                      value: params.row.category?.id,
                      label: params.row.category?.category_name,
                    },
                    sub_category: {
                      value: params.row.sub_category?.id,
                      label: params.row.sub_category?.subcategory_name,
                    },
                    product_form_data: params.row.product_form_data,
                    vendor: params.row.user,
                    product_images: params.row.product_images,
                  },
                });
              }}
            >
              <i className="icofont-edit text-success"></i>
            </button>
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={(event) => {
                event.stopPropagation(); // Prevent row click

                setIsDeletemodal(true);
                setuser(params.row);
              }}
            >
              <i className="icofont-ui-delete text-danger"></i>
            </button>
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={(event) => {
                event.stopPropagation(); // Prevent row click
                navigate("/single-product-details", { state: params.row });
              }}
            >
              <i className="icofont-eye-alt"></i>
            </button>
          </div>
        ),
      },
    ].filter((item) => {
      if (userData?.is_vendor && item.field === "full_name") {
        return false;
      }
      return true;
    });
  };

  const onDeleteSubmit = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/product/product_form/${user?.id}/`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      if (response.status === 204) {
        toast.success("Product deleted successfully");
      }
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    } finally {
      setIsDeletemodal(false);
      getProductList("");
    }
  };

  async function getProductList(search = "") {
    setIsLoading(true);
    const baseURL = `${
      process.env.REACT_APP_BASEURL
    }/api/product/product_form/?page=${paginationModel?.page + 1}&page_size=${
      paginationModel?.pageSize
    }&`;

    const searchQuery = search ? `search=${search}&` : "search=&";
    const payment_status = active ? "draft" : "paid";
    const url = userData?.is_superuser
      ? `${baseURL}${searchQuery}payment_status=paid`
      : `${baseURL}${searchQuery}user_id=${userData?.id}&payment_status=${payment_status}`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch product data. Please try again.");
      }

      const { data, count, next } = await response.json();
      setTotalRowCount(count);
      setNextPageUrl(next);
      if (userData?.is_superuser || !active) {
        setTable_row(data);
      } else {
        setTable_row_draft(data);
      }
    } catch (error) {
      console.error("Error fetching product data:", error);
      // Optionally display error to user here
    } finally {
      reset();
      setIsLoading(false);
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGetProductList = useCallback(_.debounce(getProductList, 300), [
    active,
  ]);

  useEffect(() => {
    debouncedGetProductList();
  }, [active]);

  const onStatusSubmit = async () => {
    if (
      changeStatusTo?.modelHeading === "Change Product Status" &&
      changeStatusTo?.status === "active"
    ) {
      if (
        userData?.plan_name === "Free" &&
        table_row?.filter((item) => item?.status === "active").length >= 10
      ) {
        toast.error("Please upgrade your plan");
        return;
      } else if (
        userData?.plan_name === "Silver" &&
        table_row?.filter((item) => item?.status === "active").length >= 50
      ) {
        toast.error("Please upgrade your plan");
        return;
      }
    }
    try {
      const formData = new FormData();
      Object.entries(changeStatusTo.apiParameters)?.forEach(([key, value]) => {
        formData.append(key, value);
      });
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/product/product_form/${changeStatusTo?.id}/`,
        {
          method: "PATCH",
          body: formData,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      if (response.status === 200) {
        toast.success("Product updated successfully");
      }
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    } finally {
      setIsModel(false);
      setChangeStatusTo({});
      getProductList();
    }
  };

  // Filtering Product based on Product Title
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  // Debounce the filtering function
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFilter = useCallback(
    _.debounce((input) => {
      getProductList(input.toLowerCase());
    }, 1000), // Adjust the delay as needed
    []
  );

  const handleFilterChange = (e) => {
    const value = e.target.value;
    setFilterText(value.toLowerCase());
    debouncedFilter(value);
  };

  const filteredItemsActive = useMemo(() => {
    return table_row.filter(({ title, user, sub_category }) => {
      const titleMatch = title?.toLowerCase().includes(filterText);
      const userNameMatch = user?.full_name?.toLowerCase().includes(filterText);
      const subcategoyTag = sub_category?.tags
        ?.toLowerCase()
        .includes(filterText);
      return titleMatch || userNameMatch || subcategoyTag;
    });
  }, [filterText, table_row]);

  const filteredItemsDraft = useMemo(() => {
    return table_row_draft.filter(({ title, user, sub_category }) => {
      const titleMatch = title?.toLowerCase().includes(filterText);
      const userNameMatch = user?.full_name?.toLowerCase().includes(filterText);
      const subcategoyTag = sub_category?.tags
        ?.toLowerCase()
        .includes(filterText);
      return titleMatch || userNameMatch || subcategoyTag;
    });
  }, [filterText, table_row_draft]);

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
      getProductList("");
    }
  };

  const subHeaderComponentMemo = useSubHeaderComponent({
    filterText,
    resetPaginationToggle,
    handleFilterChange,
    handleClear,
  });

  const handleChange = ({ row }) => {
    // You can set state or dispatch with something like Redux so we can use the retrieved data
    navigate("/single-product-details", { state: row });
  };

  useEffect(() => {
    getProductList(filterText);
  }, [paginationModel]);

  return (
    <div className="body d-flex py-lg-3 py-md-2">
      <div className="container-xxl">
        <PageHeader1
          pagetitle="Product Information"
          modalbutton={() => {
            return (
              <div className="col-auto d-flex w-sm-100">
                {userData?.is_vendor ? (
                  <button
                    type="button"
                    onClick={() => {
                      navigate(`/product-details`, { state: { type: "add" } });
                    }}
                    className="btn btn-primary btn-set-task w-sm-100"
                  >
                    <i className="icofont-plus-circle me-2 fs-6"></i>Add Product
                  </button>
                ) : (
                  <></>
                )}
              </div>
            );
          }}
        />
        {userData && userData?.is_vendor ? (
          <div
            className={`d-flex align-items-center justify-content-between ${st.tabsTable}`}
          >
            <ul>
              {" "}
              <li
                className={`${!active ? "active" : ""}`}
                onClick={() => {
                  setActive(false);
                }}
              >
                Active
              </li>
              <li
                className={`${active ? "active" : ""}`}
                onClick={() => {
                  setActive(true);
                }}
              >
                Draft
              </li>
            </ul>
            <ul>
              <li
                onClick={() => {
                  getProductList();
                }}
              >
                <MdOutlineRefresh />
              </li>
            </ul>
          </div>
        ) : (
          <></>
        )}
        {active ? (
          <div className="row clearfix g-3">
            <div className="col-sm-12 mt-3">
              <div className="card">
                <div className="card-body">
                  <div
                    id="myProjectTable_wrapper"
                    className="dataTables_wrapper dt-bootstrap5 no-footer"
                  >
                    <div className="row">
                      <div className="col-sm-12">
                        {isLoading ? (
                          <div
                            className="d-flex justify-content-center align-items-center"
                            style={{ minHeight: "70vh" }}
                          >
                            <div className="spinner-border" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                        ) : (
                          //   <DataTable
                          //     columns={columns()}
                          //     defaultSortField="title"
                          //     pagination
                          //     selectableRows={false}
                          //     className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline cursor-pointer"
                          //     highlightOnHover={true}
                          //     data={filteredItemsDraft}
                          //     subHeader
                          //     paginationResetDefaultPage={resetPaginationToggle}
                          //     subHeaderComponent={subHeaderComponentMemo}
                          //     persistTableHead
                          //     onRowClicked={handleChange}
                          //   />
                          <DataGrid
                            rows={filteredItemsDraft}
                            columns={columns()}
                            loading={isLoading}
                            disableColumnFilter
                            disableColumnSelector
                            disableDensitySelector
                            disableToolbarButton={true}
                            paginationMode="server"
                            pageSizeOptions={[10, 20, 50, 100]}
                            paginationModel={paginationModel}
                            rowCount={totalRowCount}
                            onPaginationModelChange={setPaginationModel}
                            slots={{
                              toolbar: CustomToolbar,
                              pagination: CustomPagination,
                            }}
                            onRowClick={handleChange}
                            slotProps={{
                              toolbar: {
                                printOptions: { disableToolbarButton: true },
                                csvOptions: { disableToolbarButton: true },
                                subHeaderComponentMemo, // Pass the memoized component to the toolbar
                              },
                            }}
                            sx={{
                              border: "none", // Remove the border of the DataGrid
                              "& .MuiDataGrid-root": {
                                border: "none",
                              },
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="row clearfix g-3">
            <div className="col-sm-12 mt-3">
              <div className="card">
                <div className="card-body">
                  <div
                    id="myProjectTable_wrapper"
                    className="dataTables_wrapper dt-bootstrap5 no-footer"
                  >
                    <div className="row">
                      <div className="col-sm-12">
                        {/* <DataTable
                          progressPending={isLoading}
                          progressComponent={
                            <div
                              className="d-flex justify-content-center align-items-center"
                              style={{ minHeight: "70vh" }}
                            >
                              <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                            </div>
                          }
                          columns={columns()}
                          defaultSortField="title"
                          pagination
                          selectableRows={false}
                          className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline cursor-pointer"
                          highlightOnHover={true}
                          data={filteredItemsActive}
                          subHeader
                          paginationResetDefaultPage={resetPaginationToggle}
                          subHeaderComponent={subHeaderComponentMemo}
                          persistTableHead
                          onRowClicked={handleChange}
                        /> */}
                        <DataGrid
                          rows={filteredItemsActive}
                          columns={columns()}
                          loading={isLoading}
                          disableColumnFilter
                          disableColumnSelector
                          disableDensitySelector
                          disableToolbarButton={true}
                          paginationMode="server"
                          pageSizeOptions={[10, 20, 50, 100]}
                          paginationModel={paginationModel}
                          rowCount={totalRowCount}
                          onPaginationModelChange={setPaginationModel}
                          slots={{
                            toolbar: CustomToolbar,
                            pagination: CustomPagination,
                          }}
                          onRowClick={handleChange}
                          slotProps={{
                            toolbar: {
                              printOptions: { disableToolbarButton: true },
                              csvOptions: { disableToolbarButton: true },
                              subHeaderComponentMemo, // Pass the memoized component to the toolbar
                            },
                          }}
                          sx={{
                            border: "none", // Remove the border of the DataGrid
                            "& .MuiDataGrid-root": {
                              border: "none",
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal
        show={isDeletemodal}
        onHide={() => {
          setIsDeletemodal(false);
        }}
        style={{ display: "block" }}
      >
        <Modal.Header className="modal-header" closeButton>
          <h5 className="modal-title  fw-bold" id="expaddLabel">
            Delete Product
          </h5>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <div className="deadline-form">
            <form>
              <h4
                style={{
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "600",
                  marginBottom: "20px",
                }}
              >
                Are you sure you want to delete?
              </h4>
              <div className={`${st.modalButtonBottom}`}>
                <button
                  onClick={() => {
                    setIsDeletemodal(false);
                  }}
                  type="button"
                  className="btn btn-secondary"
                >
                  Cancel
                </button>
                <button
                  onClick={onDeleteSubmit}
                  type="button"
                  className="btn btn-primary"
                >
                  Delete
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={ismodel}
        onHide={() => {
          setIsModel(false);
        }}
        style={{ display: "block" }}
      >
        <Modal.Header className="modal-header" closeButton>
          <h5 className="modal-title  fw-bold" id="expaddLabel">
            {changeStatusTo?.modelHeading}
          </h5>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <div className="deadline-form">
            <form>
              <h4
                style={{
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "600",
                  marginBottom: "20px",
                }}
              >
                Are you sure you want to change the status to{" "}
                {changeStatusTo.status}?
              </h4>
              <div className={`${st.modalButtonBottom}`}>
                <button
                  onClick={() => {
                    setIsModel(false);
                    setChangeStatusTo({});
                  }}
                  type="button"
                  className="btn btn-secondary"
                >
                  Cancel
                </button>
                <button
                  onClick={onStatusSubmit}
                  type="button"
                  className="btn btn-primary"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default VendorProductList;